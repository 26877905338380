import * as React from 'react';
import { faQuestion, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Filter from './filter';

interface Suggestion {
  value: string;
  [key: string]: any;
}

interface TextFilterProps {
  suggestions: Suggestion[];
  currentValue?: string;
  fetchSuggestions(term: string): void;
  onSelect(suggestion: Suggestion): void;
  onEnterOrBlur(value?: string | Suggestion): void;
  placeholder?: string;
  required?: boolean;
  itemIcons?: { [key: string]: IconDefinition };
  color?: string;
}

const TextFilter: React.FunctionComponent<TextFilterProps> = ({
  suggestions,
  currentValue,
  fetchSuggestions,
  onSelect,
  onEnterOrBlur,
  placeholder,
  required,
  itemIcons,
  color = `primary`,
}) => (
  <Filter
    icon={faQuestion}
    itemsIcons={itemIcons || {}}
    color={color}
    downshift={{
      items: suggestions,
      initialInputValue: currentValue || ``,
      inputProps: { placeholder, required },
      itemToString: (suggestion: Suggestion) => suggestion.value,
      onInputValueChange: ({ inputValue }) => {
        fetchSuggestions(inputValue);
      },
      onSelectedItemChange: ({ selectedItem }) => {
        onSelect(selectedItem);
      },
      onEnterOrBlur,
    }}
  />
);

export default TextFilter;
