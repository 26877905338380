import create, { SetState, GetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import activitiesSlice, { ActivitiesSlice } from './activities';
import articlesSlice, { ArticlesSlice } from './articles';
import citiesSlice, { CitiesSlice } from './cities';
import filtersSlice, { FiltersSlice } from './filters';
import focusSlice, { FocusSlice } from './focus';
import practitionersSlice, { PractitionersSlice } from './practitioners';

export interface StoreState {
  practitioners: PractitionersSlice;
  activities: ActivitiesSlice;
  articles: ArticlesSlice;
  filters: FiltersSlice;
  cities: CitiesSlice;
  focus: FocusSlice;
}

export type StoreSlice<T> = (
  set: SetState<StoreState>,
  get: GetState<StoreState>,
) => T;

const store = (set: SetState<StoreState>, get: GetState<StoreState>) => ({
  practitioners: { ...practitionersSlice(set, get) },
  activities: { ...activitiesSlice(set, get) },
  articles: { ...articlesSlice(set, get) },
  filters: { ...filtersSlice(set, get) },
  cities: { ...citiesSlice(set, get) },
  focus: { ...focusSlice(set, get) },
});

const useStore = create<StoreState>(
  process.env.NODE_ENV === `production` ? store : devtools(store),
);

export default useStore;
