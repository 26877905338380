import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <header>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container is-max-desktop">
          <div className="navbar-brand">
            <a className="navbar-item" href="https://www.etreplus.be/">
              <StaticImage
                src="../assets/images/etre-plus-logo-vert-pomme.jpg"
                width={100}
                height={100}
                alt="Être plus logo"
              />
            </a>

            <button
              onClick={() => setToggleMenu(!toggleMenu)}
              type="button"
              className={[
                `navbar-burger burger`,
                toggleMenu ? `is-active` : ``,
              ].join(` `)}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          </div>

          <div
            id="navbarPrimary"
            className={[`navbar-menu`, toggleMenu ? `is-active` : ``].join(` `)}
          >
            <div className="navbar-end">
              <a className="navbar-item" href="https://www.etreplus.be/">
                Accueil
              </a>
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link is-arrowless" to="/">
                  Annuaire
                </Link>
                <div className="navbar-dropdown">
                  <a
                    href="https://www.etreplus.be/annuaire"
                    className="navbar-item"
                  >
                    Présentation
                  </a>
                  <a
                    href="https://www.etreplus.be/formulesannuaire"
                    className="navbar-item"
                  >
                    Formules
                  </a>
                  <a
                    href="https://www.etreplus.be/inscriptionannuaire"
                    className="navbar-item"
                  >
                    Inscription
                  </a>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <a
                  className="navbar-link is-arrowless"
                  href="https://etreplus.be/magazine"
                >
                  Revue
                </a>
                <div className="navbar-dropdown">
                  <a
                    href="https://www.etreplus.be/blog"
                    className="navbar-item"
                  >
                    Blog
                  </a>
                  <a
                    href="https://www.etreplus.be/abonnement"
                    className="navbar-item"
                  >
                    Abonnement
                  </a>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <Link
                  className="navbar-link is-arrowless"
                  to="/agenda-des-activites-et-des-conferences/"
                >
                  Activités
                </Link>
                <div className="navbar-dropdown">
                  <Link to="/a-propos-de-l-agenda" className="navbar-item">
                    A Propos
                  </Link>
                  <a
                    href="https://www.etreplus.be/agenda"
                    className="navbar-item"
                  >
                    Agenda à la une
                  </a>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <a
                  className="navbar-link is-arrowless"
                  href="https://www.etreplus.be/ateliers"
                >
                  Evènements
                </a>
                <div className="navbar-dropdown">
                  <a
                    className="navbar-item"
                    href="https://www.etreplus.be/copie-de-film-l-%C3%A2me"
                  >
                    Film l&apos;Âme
                  </a>
                  <a
                    className="navbar-item"
                    href="https://www.etreplus.be/copie-de-colloque-1"
                  >
                    Colloque 2021
                  </a>
                  <a
                    href="https://www.etreplus.be/meditons-ensemble"
                    className="navbar-item"
                  >
                    Méditons Ensemble
                  </a>
                  <a
                    href="https://www.etreplus.be/cycle-2020-2021"
                    className="navbar-item"
                  >
                    Cycle 2020-2021
                  </a>
                  <a
                    href="https://www.etreplus.be/inscription-cycle"
                    className="navbar-item"
                  >
                    Inscription
                  </a>
                  <a
                    href="https://www.etreplus.be/nos-intervenants"
                    className="navbar-item"
                  >
                    Nos intervenants
                  </a>
                </div>
              </div>
              <a
                href="https://www.etreplus.be/conference-et-atelier-louis-fouche"
                className="navbar-item"
              >
                Venue&nbsp;L.&nbsp;Fouché
              </a>
              <div className="navbar-item has-dropdown is-hoverable">
                <a
                  className="navbar-link is-arrowless"
                  href="https://www.etreplus.be/salon-etre-plus"
                >
                  Salon
                </a>
                <div className="navbar-dropdown">
                  <a
                    href="https://www.etreplus.be/a-propos-salon"
                    className="navbar-item"
                  >
                    À Propos
                  </a>
                  <a
                    href="https://www.etreplus.be/devenir-exposant-salon"
                    className="navbar-item"
                  >
                    Devenir exposant
                  </a>
                  <a
                    href="https://www.etreplus.be/infos-pratiques-salon"
                    className="navbar-item"
                  >
                    Infos Pratiques
                  </a>
                </div>
              </div>
              <a className="navbar-item" href="https://www.etreplus.be/contact">
                Contact
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
