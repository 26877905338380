import React from 'react';

interface HeroProps {
  title: string;
  filters?: React.ReactNode;
  subTitle?: string;
}

const Hero: React.FunctionComponent<HeroProps> = ({
  title,
  filters,
  subTitle,
}) => (
  <section className="hero has-background-white-ter">
    <div className="hero-body">
      <div className="container">
        <div className="columns is-centered has-text-centered">
          <div className="column is-narrow">
            <h1 className="title is-4">
              {title}
              {subTitle && (
                <>
                  <br />
                  <small style={{ fontSize: `0.9rem` }}>{subTitle}</small>
                </>
              )}
            </h1>
            {filters}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
