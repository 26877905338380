import produce from 'immer';
import { StoreSlice } from '.';

export interface FocusSlice {
  focus?: number;
  setFocus(index: number): void;
}

const focusSlice: StoreSlice<FocusSlice> = (set, get) => ({
  setFocus: (index) => {
    set(
      produce((state) => {
        state.focus.focus = index;
      }),
    );
  },
});

export default focusSlice;
