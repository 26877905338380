import * as React from 'react';
import { GetInputPropsOptions, useCombobox, UseComboboxProps } from 'downshift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import matchString from 'match-string';

interface ItemsIcons<T = any> {
  [key: string]: IconDefinition;
}

interface FilterProps<T = any> {
  icon: IconDefinition;
  itemsIcons?: ItemsIcons<T>;
  color: string;
  downshift: {
    onEnterOrBlur?(value: string | T): void;
    inputProps: GetInputPropsOptions;
    items: UseComboboxProps<T>['items'];
    itemToString: UseComboboxProps<T>['itemToString'];
    onInputValueChange: UseComboboxProps<T>['onInputValueChange'];
    onSelectedItemChange: UseComboboxProps<T>['onSelectedItemChange'];
    initialInputValue?: UseComboboxProps<T>['initialInputValue'];
    defaultHighlightedIndex?: UseComboboxProps<T>['defaultHighlightedIndex'];
    initialHighlightedIndex?: UseComboboxProps<T>['initialHighlightedIndex'];
  };
}

const WrapWithIcon = ({ item, itemsIcons }) => (
  <span className="icon-text" style={{ flexWrap: `nowrap` }}>
    <span className="icon">
      {itemsIcons._all || itemsIcons[item.field] ? (
        <FontAwesomeIcon icon={itemsIcons._all || itemsIcons[item.field]} />
      ) : undefined}
      <i className="fas fa-home" />
    </span>
    <span>{item.value}</span>
  </span>
);

const Filter: React.FunctionComponent<FilterProps> = ({
  downshift,
  icon,
  itemsIcons = {},
  color = `primary`,
}) => {
  function stateReducer(state, actionAndChanges) {
    const { type, changes } = actionAndChanges;

    try {
      /*  const item =
        changes.inputValue &&
        (changes.selectedItem ||
          downshift.items.find(
            (item) =>
              changes.inputValue &&
              matchString(item.value, `i`).test(changes.inputValue) &&
              changes.inputValue.trim().length === item.value.length,
          ));
 */
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputBlur: {
          if (downshift.onEnterOrBlur) {
            downshift.onEnterOrBlur(/* item ||  */ changes.inputValue);
          }
          return changes;
        }
        default:
          return changes;
      }
    } catch (err) {
      downshift.onEnterOrBlur(``);
      console.error(err);
    }
    // Should not be reached
    return changes;
  }

  const controlProps = {
    className: `control has-icons-left`,
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useCombobox({
    stateReducer,
    items: downshift.items,
    initialInputValue: downshift.initialInputValue,
    itemToString: downshift.itemToString,
    onInputValueChange: downshift.onInputValueChange,
    onSelectedItemChange: downshift.onSelectedItemChange,
    defaultHighlightedIndex: downshift.defaultHighlightedIndex,
    initialHighlightedIndex: downshift.initialHighlightedIndex,
  });

  const inputProps = {
    // required: true,
    ...downshift.inputProps,
    className: classNames(downshift.inputProps.className, `input`),
    onFocus: () =>
      !isOpen && downshift.items && downshift.items[0] && openMenu(),
  };

  return (
    <div className="field" style={{ position: `relative` }}>
      <div {...getComboboxProps(controlProps)}>
        <span className="icon is-small is-left">
          <FontAwesomeIcon className={`has-text-${color}`} icon={icon} />
        </span>
        <input {...getInputProps({ ...inputProps })} />
      </div>
      <div {...getMenuProps()}>
        {isOpen && (
          <ul
            style={{
              maxHeight: `250px`,
              overflow: `scroll`,
              position: `absolute`,
              zIndex: 10,
              width: `100%`,
            }}
            className="filter-dropdown"
          >
            {downshift.items.map((item, index) => (
              <li
                key={`${item.value}-${item.field}-${item.count}`}
                {...getItemProps({
                  item,
                  index,
                  className: classNames([
                    `py-2 has-text-left pl-2 pr-1`,
                    highlightedIndex === index
                      ? `has-background-${color}`
                      : `has-background-white`,
                  ]),
                })}
              >
                <WrapWithIcon item={item} itemsIcons={itemsIcons} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Filter;
