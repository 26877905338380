import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'gatsby';

const Footer = () => (
  <>
    <footer className="footer background-image-wood pb-5">
      <div className="container has-text-white has-text-centered is-max-desktop">
        <div className="columns">
          <div className="column" style={{ borderRight: `1px solid white` }}>
            <p>
              31, Rue de la Terre Franche
              <br />
              5310 Longchamps, Belgique
              <br />
              hello@etreplus.be
            </p>
          </div>
          <div
            className="column playfair"
            style={{ borderRight: `1px solid white` }}
          >
            <p className="is-size-5 has-text-weight-bold mt-5">ÊTRE PLUS</p>
          </div>
          <div className="column">
            <p className="mb-3">Tél: 081 43 24 80</p>
            <p>
              <a
                href="https://www.facebook.com/etreplus.be/"
                className="has-text-white is-size-5"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </p>
          </div>
        </div>
        <div className="has-text-centered mt-6 is-size-6">
          &copy; {new Date().getFullYear()} Être Plus. Tous droits réservés.
        </div>
      </div>
    </footer>
    <section className="is-size-7 px-1 py-1 has-text-centered has-background-dark">
      <ul>
        <li className="is-inline-block mx-3">
          <Link
            to="/annuaire-therapeutes-par-région-province-ville"
            className=" has-text-grey-light"
          >
            Liste de nos thérapeutes classés par région, province et ville
          </Link>
        </li>
        <li className="is-inline-block  mx-3">
          <Link
            to="/annuaire-therapeutes-par-catégorie"
            className=" has-text-grey-light"
          >
            Liste de nos thérapeutes classés par Catégorie
          </Link>
        </li>
      </ul>
    </section>
  </>
);

export default Footer;
