import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Header from './header';
import '@/assets/styles/mystyles.scss';
import Footer from './footer';
import Seo from './seo';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <>
        <Header />
        <Helmet>
          <link rel="icon" href="/favicon.ico" sizes="any" />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
          <link rel="manifest" href="/site.webmanifest" />
        </Helmet>
        <Seo title="Votre boussole médecine douce, bien-être et transition" />
        <div className="hero hero-background-image is-medium is-hidden-mobile">
          <div className="hero-body has-text-centered">
            <p className="is-size-4 has-text-white is-italic">
              Bien-Être - Santé - Spiritualité - Développement personnel -
              Alimentation BIO
            </p>
          </div>
        </div>
        <main>{children}</main>
        <Footer />
      </>
    )}
  />
);

export default Layout;
