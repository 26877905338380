import produce from 'immer';
import app from '@/core/api';
import { Article } from '../../../backend/src/models/articles.model';
import { StoreSlice } from '.';
import { SuggestionsParams } from './common.types';

export interface ArticleSuggestion {
  _score: number;
  value: string;
  count: number;
  field: 'category.title' | 'title';
}

export interface ArticlesSlice {
  all: Article[];
  suggestions: ArticleSuggestion[];
  current?: string;
  find(params): Promise<void>;
  findSuggestions(params?: SuggestionsParams): Promise<void>;
  currentSuggestion?: ArticleSuggestion;
  currentSearch?: string;
  setCurrentSuggestion(suggestion: ArticleSuggestion): void;
  setCurrentSearch(value: string): void;
}

const articlesSlice: StoreSlice<ArticlesSlice> = (set, get) => ({
  all: [],
  suggestions: [],
  current: undefined,
  currentSearch: undefined,
  currentSuggestion: undefined,
  find: async (params) => {
    const res = await app
      .service(`articles`)
      .find({ query: { $limit: 300, ...params } });
    set(
      produce((state) => {
        state.articles.all = res.data;
      }),
    );
  },
  findSuggestions: async (params) => {
    if (!params) params = {};
    if (!params.term) {
      params.$limit = 20;
      params.$select = [`category`];
    }
    const res = await app
      .service(`articles/suggestions`)
      .find({ query: params });
    set(
      produce((state) => {
        state.articles.suggestions = Array.isArray(res) ? res : [];
      }),
    );
  },
  setCurrentSuggestion: (suggestion) => {
    set(
      produce((state) => {
        state.articles.currentSearch = undefined;
        state.articles.currentSuggestion = suggestion;
      }),
    );
  },
  setCurrentSearch: (value) => {
    set(
      produce((state) => {
        state.articles.currentSuggestion = undefined;
        state.articles.currentSearch = value;
      }),
    );
  },
});

export default articlesSlice;
