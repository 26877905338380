import produce from 'immer';
import app from '@/core/api';
import { StoreSlice } from '.';

const defaultSuggestions = [
  {
    value: `Bruxelles`,
    latitude: `50.84667`,
    longitude: `4.35114`,
  },
  {
    value: `Wavre`,
    latitude: `50.69364`,
    longitude: `4.57404`,
  },
  {
    value: `Liège`,
    latitude: `50.6479`,
    longitude: `5.54366`,
  },
  {
    value: `Namur`,
    latitude: `50.46875`,
    longitude: `4.92181`,
  },
  {
    value: `Charleroi`,
    latitude: `50.41146`,
    longitude: `4.44424`,
  },
  {
    value: `Mons`,
    latitude: `50.45333`,
    longitude: `3.94874`,
  },
];

export interface CitySuggestion {
  latitude: string | number;
  longitude: string | number;
  _score: number;
  value: string;
  count: number;
}

export interface SuggestionsParams {
  term: string;
}

export interface CitiesSlice {
  all: unknown[];
  current?: unknown;
  currentSuggestion?: CitySuggestion;
  findSuggestions(params?: SuggestionsParams): Promise<void>;
  setCurrentSuggestion(CitySuggestion?): void;
  clear(): void;
}

const citiesSlice: StoreSlice<CitiesSlice> = (set, get) => ({
  all: [],
  findSuggestions: async (params) => {
    if (!params.term)
      set(
        produce((state) => {
          state.cities.all = defaultSuggestions;
        }),
      );
    else {
      const res = await app
        .service(`/cities/suggestions`)
        .find({ query: params });
      set(
        produce((state) => {
          state.cities.all = Array.isArray(res) ? res : [];
        }),
      );
    }
  },
  setCurrentSuggestion: (city) => {
    set(
      produce((state) => {
        state.cities.currentSuggestion = city;
      }),
    );
  },
  clear: () => {
    set(
      produce((state) => {
        state.cities.all = [];
      }),
    );
  },
});

export default citiesSlice;
