import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface FiltersSubmitButtonProps {
  onClick(): void;
  color?: string;
}

const FiltersSubmitButton: React.FunctionComponent<
  FiltersSubmitButtonProps
> = ({ onClick, color = `primary` }) => (
  <div className="control">
    <button type="submit" className={`button is-${color}`} onClick={onClick}>
      <span className="icon is-small">
        <FontAwesomeIcon className="has-text-white" icon={faSearch} />
      </span>
      <span>Chercher</span>
    </button>
  </div>
);

export default FiltersSubmitButton;
