import produce from 'immer';
import app from '@/core/api';
import { Practitioner } from '../../../backend/src/models/practitioners.model';
import { StoreSlice } from '.';
import { Filters } from './filters';
import { SuggestionsParams } from './common.types';

export interface PractitionerSuggestion {
  _score: number;
  value: string;
  count: number;
  field: 'category.title' | 'title';
}

export interface PractitionersSlice {
  all: Practitioner[];
  current?: string;
  suggestions: PractitionerSuggestion[];
  currentSuggestion?: PractitionerSuggestion;
  currentSearch?: string;
  find(params): Promise<void>;
  findSuggestions(params?: SuggestionsParams): Promise<void>;
  setCurrentSuggestion(suggestion: PractitionerSuggestion): void;
  setCurrentSearch(value: string): void;
  fetch(params?: Filters): Promise<void>;
  clear(): void;
}

const practitionersSlice: StoreSlice<PractitionersSlice> = (set, get) => ({
  all: [],
  current: undefined,
  suggestions: [],
  currentSuggestion: null,
  currentSearch: null,
  fetch: async (params) => {
    const res = await app.service(`practitioners`).find();
    set(
      produce((state) => {
        state.practitioners.all = res.data;
      }),
    );
  },
  find: async (params) => {
    const res = await app.service(`practitioners`).find({ query: params });
    set(
      produce((state) => {
        state.practitioners.all = res.data;
      }),
    );
  },
  findSuggestions: async (params) => {
    if (!params) params = {};
    if (!params.term) {
      params.$limit = 20;
      params.$select = [`category`];
    }
    const res = await app
      .service(`practitioners/suggestions`)
      .find({ query: params });
    set(
      produce((state) => {
        state.practitioners.suggestions = Array.isArray(res) ? res : [];
      }),
    );
  },
  setCurrentSuggestion: (suggestion) => {
    set(
      produce((state) => {
        state.practitioners.currentSearch = undefined;
        state.practitioners.currentSuggestion = suggestion;
      }),
    );
  },
  setCurrentSearch: (value: string) => {
    set(
      produce((state) => {
        state.practitioners.currentSuggestion = undefined;
        state.practitioners.currentSearch = value;
      }),
    );
  },
  clear: () => {
    set(
      produce((state) => {
        state.practitioners.all = [];
        state.practitioners.suggestions = [];
      }),
    );
  },
});

export default practitionersSlice;
