import produce from 'immer';
import { StoreSlice } from '.';

export interface CommonFilters {
  limit: number;
}

export interface PractitionersFilters {
  test: boolean;
}

export interface Filters {
  common: CommonFilters;
  practitioners: PractitionersFilters;
}

export interface FiltersSlice {
  common: CommonFilters;
  practitioners: PractitionersFilters;
  setFilters(filters: Filters): void;
  setFilter(key: keyof Filters, value: any): void;
}

const filtersSlice: StoreSlice<FiltersSlice> = (set, get) => ({
  common: {
    limit: 20,
  },
  practitioners: {
    test: true,
  },
  setFilter: (key, value) => {
    set(
      produce((state) => {
        state.filters.filters[key] = value;
      }),
    );
  },
  setFilters: (filters) => {
    set(
      produce((state) => {
        state.filters.filters = filters;
      }),
    );
  },
});

export default filtersSlice;
